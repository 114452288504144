import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ProjectModal from "./ProjectModal";
import { apiSchemeDetails, getOpportunity } from "../../utils/api/api";
import { isSubAdmin, toLocalePriceNoDecimal } from "../../utils/StringUtil";
import ProjectDeleteModal from "./ProjectDeleteModal";
import styles from "./Project.module.css";
import {
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SPRINTVC_INVESTOR_URL,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import PeopleIcon from "@mui/icons-material/People";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ValuationUpdate from "./ValuationUpdate";
import ValuationHistory from "./ValuationHistory";
import EmailSchemeLaunch from "./EmailSchemeLaunch";
import SchemeInfo from "./SchemeInfo";

const Opportunity = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [opportunityList, setOpportunityList] = useState([]);
  const [editOpportunity, setEditOpportunity] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filterSchemeType, setFilterSchemeType] = useState(-1);
  const [filterSchemeStatus, setFilterSchemeStatus] = useState(1502);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openEmailSchemeLaunch, setOpenEmailSchemeLaunch] = useState(false);
  const [openSchemeInfo, setOpenSchemeInfo] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const [openValuationUpdate, setOpenValuationUpdate] = useState(false);
  const toggleValuationUpdate = (index, id) => {
    if (openValuationUpdate) {
      setOpenValuationUpdate(false);
    } else {
      setEditOpportunity(opportunityList.find((item) => item.oppRid === id));
      setOpenValuationUpdate(true);
      handleMenuClose(index);
    }
  };
  const handleValuationUpdateSave = () => {
    getOpportunityList();
    toggleValuationUpdate();
  };

  const [openValuationHistory, setOpenValuationHistory] = useState(false);
  const toggleValuationHistory = (index, id) => {
    if (openValuationHistory) {
      setOpenValuationHistory(false);
    } else {
      setEditOpportunity(opportunityList.find((item) => item.oppRid === id));
      setOpenValuationHistory(true);
      handleMenuClose(index);
    }
  };

  const [anchorElMap, setAnchorElMap] = useState({});
  const handleMenuOpen = (event, index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };
  const handleMenuClose = (index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };

  const toggleEmailSchemeLaunch = (index, id) => {
    if (openEmailSchemeLaunch) {
      setOpenEmailSchemeLaunch(false);
    } else {
      setEditOpportunity(opportunityList.find((item) => item.oppRid === id));
      setOpenEmailSchemeLaunch(true);
      handleMenuClose(index);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setIsEdit(false);
    setEditOpportunity({});
  };

  const handleOpen = () => {
    setOpen(true);
    setEditOpportunity(null);
  };

  const handleSchemeInvestor = (scheme) => {
    {
      userType === "ADMIN"
        ? history.push("/admin/scheme/investor", { schemeRid: scheme.oppRid })
        : history.push("/partner/scheme/investor", {
            schemeRid: scheme.oppRid,
          });
    }
  };

  const handleSchemeDescription = (scheme) => {
    {
      userType === "ADMIN"
        ? history.push("/admin/scheme/description", {
            schemeRid: scheme.oppRid,
          })
        : history.push("/partner/scheme/description", {
            schemeRid: scheme.oppRid,
          });
    }
  };

  const handleEdit = async (id) => {
    const param = {
      oppRid: id,
    };
    apiSchemeDetails(param)
      .then((data) => {
        setEditOpportunity(data);
        setOpen(true);
        setIsEdit(true);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
    // setEditOpportunity(opportunityList.find((item) => item.oppRid === id));
    // setOpen(true);
    // setIsEdit(true);
  };

  const handleDelete = (id) => {
    setEditOpportunity(opportunityList.find((item) => item.oppRid === id));
    setIsEdit(true);
    setOpenDelete(true);
  };

  const toggleSchemeInfo = (data) => {
    const param = {
      oppRid: data?.oppRid,
    };
    if (openSchemeInfo) {
      setOpenSchemeInfo(false);
    } else {
      apiSchemeDetails(param)
        .then((data) => {
          setEditOpportunity(data);
          setOpenSchemeInfo(true);
        })
        .catch((error) => {
          enqueueSnackbar(error, SNACKBAR_ERROR);
        });
    }
  };

  const getOpportunityList = (param = {}) => {
    setIsLoading(true);
    param.pageNumber = page;
    param.recLimit = RECORD_DISPLAY_LIMIT;
    param.isPrivate = filterSchemeType < 0 ? null : filterSchemeType;
    param.oppStatus = filterSchemeStatus < 0 ? null : filterSchemeStatus;
    getOpportunity(param)
      .then((data) => {
        setIsLoading(false);
        setOpportunityList(data.opportunityList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getOpportunityList({ pageNumber: page, name: searchTerm.trim() });
  }, [page]);

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getOpportunityList();
      } else {
        return;
      }
    } else {
      getOpportunityList({ name: searchTerm.trim() });
    }

    setPage(1);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSave = () => {
    setPage(1);
    getOpportunityList();
    handleClose();
  };

  useEffect(() => {
    getOpportunityList();
  }, [filterSchemeType, filterSchemeStatus]);

  const handleSchemeLinkCopy = (index, scheme) => {
    navigator.clipboard.writeText(`${SPRINTVC_INVESTOR_URL}/user/company-pdp/${scheme?.oppRid}`);
    handleMenuClose(index);

    enqueueSnackbar('Scheme link has been copied to clipboard', SNACKBAR_SUCCESS);
  }

  return (
    <Layout title="Scheme">
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}
      <ProjectModal
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        project={editOpportunity}
        handleSave={() => handleSave()}
      />

      <SchemeInfo
        open={openSchemeInfo}
        onClose={toggleSchemeInfo}
        project={editOpportunity}
      />

      <ProjectDeleteModal
        open={openDelete}
        onClose={handleClose}
        project={editOpportunity}
        handleSave={() => handleSave()}
      />

      <ValuationUpdate
        open={openValuationUpdate}
        toggleDialog={toggleValuationUpdate}
        scheme={editOpportunity}
        handleSave={handleValuationUpdateSave}
      />
      <ValuationHistory
        open={openValuationHistory}
        toggleDialog={toggleValuationHistory}
        scheme={editOpportunity}
      />

      <EmailSchemeLaunch
        open={openEmailSchemeLaunch}
        toggleDrawer={toggleEmailSchemeLaunch}
        scheme={editOpportunity}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-3">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{ border: "1px solid #000" }}
            >
              <div className="my-auto">
                <SearchIcon />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular"
                placeholder="Search by name"
              />
              <div onClick={() => handleClearSearch()} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>

            <FormControl sx={{ width: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">Scheme Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterSchemeType}
                label="Scheme Type"
                onChange={(e) => setFilterSchemeType(parseInt(e.target.value))}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="0">Public</MenuItem>
                <MenuItem value="1">Private</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: 150 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Scheme Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterSchemeStatus}
                label="Scheme Status"
                onChange={(e) =>
                  setFilterSchemeStatus(parseInt(e.target.value))
                }
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="1501">Coming Soon</MenuItem>
                <MenuItem value="1502">Open</MenuItem>
                <MenuItem value="1503">Closed</MenuItem>
                {/* <MenuItem value="1504">Cancelled</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              <table
                className="table table-striped table-borderless w-100"
                id="order-table"
              >
                <thead>
                  <tr className="fs-normal-semibold text-center">
                    <th scope="col">
                      <p className="mb-2">Actions</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2">Scheme Title</p>
                    </th>
                    <th scope="col" className="pe-5">
                      <p className="mb-2 text-end">Round Size</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2">Domain</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2">Investment Round</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2">Status</p>
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                {opportunityList ? (
                  <tbody className="bg-table-blue">
                    {opportunityList.map((item, index) => {
                      return (
                        <tr
                          className="fs-normal-medium text-center"
                          key={index}
                        >
                          <td className="p-0">
                            <div className="d-flex">
                              <Tooltip title="Investors">
                                <IconButton
                                  onClick={() => handleSchemeInvestor(item)}
                                >
                                  <PeopleIcon className="text-dark" />
                                </IconButton>
                              </Tooltip>
                              <IconButton
                                onClick={() => toggleSchemeInfo(item)}
                              >
                                <InfoIcon
                                  fontSize="small"
                                  className="text-blue"
                                />
                              </IconButton>
                              {userType === "ADMIN" && !isSubAdmin() && (
                                <IconButton
                                  onClick={() => handleEdit(item.oppRid)}
                                  aria-label="edit"
                                >
                                  <EditIcon className="text-blue" />
                                </IconButton>
                              )}
                              {userType === "ADMIN" && !isSubAdmin() && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleDelete(item.oppRid);
                                  }}
                                >
                                  <DeleteOutlineIcon className="text-danger" />
                                </IconButton>
                              )}
                            </div>
                          </td>
                          <td className="pt-3">
                            {item.oppStatusIndex === 1503 &&
                            item.uaPending > 0 ? (
                              <Tooltip
                                title={`${item.uaTotal - item.uaPending}/${
                                  item.uaTotal
                                } Unit Allotted`}
                              >
                                <span className="text-danger">
                                  {item.oppTitle}
                                </span>
                              </Tooltip>
                            ) : (
                              item.oppTitle
                            )}
                          </td>
                          <td className="pt-3 text-end pe-5">
                            {toLocalePriceNoDecimal(item.roundSize)}
                          </td>
                          <td className="pt-3">{item.domain}</td>
                          <td className="pt-3">{item.invRound}</td>
                          <td className="pt-3">{item.oppStatus}</td>
                          <td className="pt-0">
                            {userType === "ADMIN" && !isSubAdmin() && (
                              <IconButton
                                id="basic-button"
                                aria-controls={
                                  Boolean(anchorElMap[index])
                                    ? "basic-menu"
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  Boolean(anchorElMap[index])
                                    ? "true"
                                    : undefined
                                }
                                onClick={(e) => handleMenuOpen(e, index)}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                            )}
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorElMap[index]}
                              open={Boolean(anchorElMap[index])}
                              elevation={1}
                              onClose={() => handleMenuClose(index)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              color="secondary"
                            >
                              <MenuItem
                                className="fs-med-regular"
                                onClick={() => handleSchemeDescription(item)}
                              >
                                Scheme Description
                              </MenuItem>

                              <MenuItem
                                className="fs-med-regular"
                                onClick={() =>
                                  toggleEmailSchemeLaunch(index, item.oppRid)
                                }
                              >
                                Email Scheme Launch
                              </MenuItem>
                              {item.fundTransferred ? (
                                <MenuItem
                                  className="fs-med-regular"
                                  onClick={() =>
                                    toggleValuationUpdate(index, item.oppRid)
                                  }
                                >
                                  Valuation Update
                                </MenuItem>
                              ) : (
                                ""
                              )}
                              {item.fundTransferred ? (
                                <MenuItem
                                  className="fs-med-regular"
                                  onClick={() =>
                                    toggleValuationHistory(index, item.oppRid)
                                  }
                                >
                                  Valuation History
                                </MenuItem>
                              ) : (
                                ""
                              )}
                              <MenuItem
                                className="fs-med-regular"
                                onClick={() => handleSchemeLinkCopy(index, item)}
                              >
                                Copy Scheme Link
                              </MenuItem>
                            </Menu>

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <p>No Data..</p>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Opportunity;
