import React, { useEffect, useState } from 'react';
import DialogLayout from '../../../components/layout/DialogLayout';
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { apiUpdateDematerialization } from '../../../utils/api/api';
import { useSnackbar } from 'notistack';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../utils/constants/constants';

const DematDetailsDialog = ({ open, toggleDialog, invOpp, handleSave }) => {
  const [actionLoader, setActionLoader] = useState(false);
  const [dematDetails, setDematDetails] = useState({});
  const {enqueueSnackbar} = useSnackbar();

  const handleDematDetailsChange = (event) => {
    const { name, value } = event.target;
    if (name === 'unitsAllotted') {
      const regex = /^\d+(\.\d{0,3})?$/;
      if (regex.test(value) || value === '') {
        setDematDetails({
          ...dematDetails,
          [name]: value,
        });
      }
    } else {
      setDematDetails({
        ...dematDetails,
        [name]: value,
      });
    }
  }

  const saveDematDetails = () => {
    const reqBody = {
      ...dematDetails,
      ioRid: invOpp?.ioRid
    };
    setActionLoader(true);
    apiUpdateDematerialization(reqBody)
    .then((data) => {
      setActionLoader(false);
      enqueueSnackbar('Data Saved',SNACKBAR_SUCCESS);
      handleSave();
    })
    .catch((error) => {
      setActionLoader(false);
      enqueueSnackbar(error,SNACKBAR_ERROR);
    });
  }

  useEffect(() => {
    if(open){
      setDematDetails({
        isin: invOpp?.isin,
        unitsAllotted: invOpp?.unitsAllotted,
        aditionalCharges: invOpp?.aditionalCharges,
        nsdlCdsl: invOpp?.nsdlCdsl,
        dematCreditDate: invOpp?.dematCreditDate?.split(' ')[0],
        dematerializationRemark: invOpp?.dematerializationRemark,
        aditionalChargesNarration: invOpp?.aditionalChargesNarration
      });
    }
  }, [open]);

  return (
    <DialogLayout
      open={open}
      title="Record Demat Details"
      subtitle={invOpp?.invFullName}
      maxWidth={'sm'}
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{label: 'Save', onClick : saveDematDetails}}
    >
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="isin"
            label="ISIN Code"
            variant="outlined"
            value={dematDetails?.isin ?? ''}
            onChange={(e) => handleDematDetailsChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="unitsAllotted"
            label="Units Allotted"
            variant="outlined"
            value={dematDetails?.unitsAllotted ?? ''}
            onChange={(e) => handleDematDetailsChange(e)}
            inputProps={{
              inputMode: 'decimal',
              pattern: '^[0-9]+(\\.[0-9]{0,3})?$',
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">NSDL / CDSL</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="nsdlCdsl"
              value={dematDetails?.nsdlCdsl || ''}
              label="NSDL / CDSL"
              onChange={(e) => handleDematDetailsChange(e)}
            >
              <MenuItem value="NSDL">NSDL</MenuItem>
              <MenuItem value="CDSL">CDSL</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            type="date"
            name="dematCreditDate"
            label="Demat Credit Date"
            variant="outlined"
            value={dematDetails?.dematCreditDate ?? null}
            onChange={(e) => handleDematDetailsChange(e)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            name="dematerializationRemark"
            label="Remark"
            variant="outlined"
            value={dematDetails?.dematerializationRemark ?? ''}
            onChange={(e) => handleDematDetailsChange(e)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <span className='section-label'>Charges</span>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="aditionalCharges"
            label="Additional Charges"
            variant="outlined"
            value={dematDetails?.aditionalCharges ?? ''}
            onChange={(e) => handleDematDetailsChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            type="date"
            name="chargesDatetime"
            label="Charges Date"
            variant="outlined"
            value={dematDetails?.chargesDatetime ?? null}
            onChange={(e) => handleDematDetailsChange(e)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            name="aditionalChargesNarration"
            label="Additional charges remark"
            variant="outlined"
            value={dematDetails?.aditionalChargesNarration ?? ''}
            onChange={(e) => handleDematDetailsChange(e)}
            fullWidth
          />
        </Grid>
      </Grid>
    </DialogLayout>
  );
}

export default DematDetailsDialog;